import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Card, Col, Form, FloatingLabel, Tabs, Tab, Alert } from "react-bootstrap";
import { format, subDays } from "date-fns";
import { pAxios } from "..";
import fileDownload from 'js-file-download'
import Table from "./Table";
import { warningNotification } from "../action/notificationAction";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  setEnableMobileMenu
} from '../constant/constant';
import { formatCurrency } from "../constant/format";
import { filterClientsSearch, filterLabourSearch, filterProjectsSearch, filterUsersSearch, filterVendorsSearch } from "../constant/filterTypeahead";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';



export default function Statement({ minDate, permitted }) {


  const cltTypeRef = React.useRef(null);
  const venTypeRef = React.useRef(null);
  const proTypeRef = React.useRef(null);
  const labTypeRef = React.useRef(null);
  const userTypeRef = React.useRef(null);

  const [report, setReport] = useState('');


  const getCurrentFinancialYear = () => {
    var fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear = (today.getFullYear() - 1)
    } else {
      fiscalyear = today.getFullYear()
    }
    return fiscalyear
  }

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(minDate ? new Date(minDate) : subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [selected, setSelected] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [selectedSide, setSelectedSide] = useState("0");
  const [tableData, setTableData] = useState([]);
  const [generalExp, setGeneralExp] = useState(false);
  const [allExp, setAllExp] = useState(false);
  const [execludeAuto, setExecludeAuto] = useState(false);

  const [accountPermission, setAccountPermission] = React.useState(permitted(`REPORT_ACCOUNT_STATEMENT`));
  const [invoicePermission, setInvoicePermission] = React.useState(permitted(`REPORT_INVOICE_STATEMENT`));
  const [paymentPermission, setPaymentPermission] = React.useState(permitted(`REPORT_PAYMENT_STATEMENT`));
  const [expensePermission, setExpensePermission] = React.useState(permitted(`REPORT_EXPENSE_STATEMENT`));

  const clients = useSelector(
    (state) => state.lookup.client
  );

  const vendors = useSelector(
    (state) => state.lookup.vendor
  );

  const projects = useSelector(
    (state) => state.lookup.project
  );


  const users = useSelector(
    (state) => state.lookup.user
  );

  const labours = useSelector(
    (state) => state.lookup.labour);


  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );

  const onChange = (dates) => {

    if (dates && dates.length > 0) {

      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else {
      setStartDate(null);
      setEndDate(null);

    }

  };



  // eslint-disable-next-line no-unused-vars
  const [ownPermission, setOwnPermission] = React.useState(permitted("EXPENSE_OWN"));

  React.useEffect(() => {
    setSelectedSide("0");
    setSelected(null);
    setTableData([]);
    setSelectedName(null);
    // setStartDate(new Date(getCurrentFinancialYear() + "-04-01"));
    setEndDate(new Date());
    setSelectedProject(null);
    setSelectedProjectName(null);
    setTableData([]);

    proTypeRef?.current?.clear();
    labTypeRef?.current?.clear();
    cltTypeRef?.current?.clear();

    if(report==='' || !report){

      if(accountPermission){
        setReport('Account');
      }
      else if(invoicePermission){
        setReport('Invoice');
      }
      else if(paymentPermission){
        setReport('Payment');
      }
      else if(expensePermission){
        setReport('Expense');
      }
    }

  }, [report, costcenter]);

  const handleDownload = () => {

    if (startDate && endDate && ((selectedSide !== null && selectedSide !== "0") || report === 'Expense' || report ==='Company')) {

      const data = {
        fromDate: format(startDate, "yyyy-MM-dd"),
        toDate: format(endDate, "yyyy-MM-dd"),
        side: selectedSide && selectedSide !== "0" ? selectedSide : null,
        execludeAuto: execludeAuto,
        selectedId: selected !== null ? selected : null,
        selectedName: selectedName ?? selectedProjectName ?? "ALL",
        projectid: selectedProject ?? null,
        projectname: selectedProjectName ?? null,
        exptype: report === 'Expense' ? allExp ? 'ALL' : generalExp ? 'GENERAL' : 'PROJECT' : null
      }

      let url = Object.keys(data).filter((k) => {
        return data[k] !== null
      }).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
      }).join('&')

      pAxios({
        "method": "GET",
        "url": "/api/auth",

      }).then(r => {

        pAxios.get(`/api/statement/${report === "Payment" ? "pay" : report === "Invoice" ? "invoice" : report === "Expense" ? "exp" : "account"}/download?${url}`, {
          responseType: 'blob',
        })
          .then((res) => {
            fileDownload(res.data, `Statement of ${report !== 'Expense' ? `${selectedSide.toLowerCase()}` : ''}  ${report}-${selectedName ?? 'all'}-${format(startDate, "yyyy-MM-dd")}-${format(endDate, "yyyy-MM-dd")}.XLSX`)
          })
      })

    }
    else {
      dispatch(warningNotification("Select date range, client or vendor"));
    }
  }


  const search = () => {

    if (startDate && endDate && ((selectedSide !== null && selectedSide !== "0") || report === 'Expense' || report==='Company')) {

      const data = {
        fromDate: format(startDate, "yyyy-MM-dd"),
        toDate: format(endDate, "yyyy-MM-dd"),
        side: selectedSide,
        json: true,
        selectedId: selected != null ? selected : null,
        selectedName: selectedName ?? selectedProjectName ?? "ALL",
        projectid: selectedProject ?? null,
        projectname: selectedProjectName ?? null,
        execludeAuto: execludeAuto,
        exptype: report === 'Expense' ? allExp ? 'ALL' : generalExp ? 'GENERAL' : 'PROJECT' : null
      }

      let url = Object.keys(data).filter((k) => {
        return data[k] !== null
      }).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
      }).join('&')

      pAxios.get(`/api/statement/${report === "Payment" ? "pay" : report === "Invoice" ? "invoice" : report === "Expense" ? "exp" : report === "Account" ? "account" : "company"}/download?${url}`)
        .then((res) => {
          setTableData(res.data)
        })
        .catch(err => {
          setTableData([]);
          dispatch(warningNotification("Failed to load report data"))
        })

    }
    else {
      dispatch(warningNotification("Select a date range , client or vendor to load report"))
    }

  }


  const columnsInvoice = React.useMemo(
    () => [

      {
        Header: 'Date',
        accessor: 'date',
        id: 'date'
      },
      {
        Header: 'Name',
        accessor: 'name'

      },
      {
        Header: 'Project',
        accessor: 'project',
      },
      {
        Header: 'Invoice Number',
        accessor: 'number',
      },
      {
        Header: 'Basic Amount',
        accessor: (cell) => formatCurrency(cell['basic']),
        className: "text-end"
      },
      {
        Header: 'Gross Amount',
        accessor: (cell) => formatCurrency(cell['gross']),
        className: "text-end"
      }, {
        Header: 'TDS',
        accessor: (cell) => formatCurrency(cell['tds'] ?? 0.00),
        className: "text-end"
      }, {
        Header: 'Cr/Dr Note',
        accessor: (cell) => formatCurrency(cell['credit_note'] ?? 0.00),
        className: "text-end"
      }, {
        Header: 'Allocated',
        accessor: (cell) => formatCurrency(cell['allocated'] ?? 0.00),
        className: "text-end"
      }, {
        Header: 'Balance',
        accessor: (cell) => formatCurrency(cell['balance']),
        className: "text-end"
      }],
    []
  );

  const columnsPayment = React.useMemo(
    () => [

      {
        Header: 'Date',
        accessor: 'date',
        id: 'date'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Project',
        accessor: 'project',
      },
      {
        Header: 'Amount',
        accessor: (cell) => formatCurrency(cell['gross']),
        className: "text-end"
      },
      {
        Header: 'Mode',
        accessor: 'mode',
      }, {
        Header: 'Reference',
        accessor: 'reference',
        className: 'w-150 text-truncate'
      }, {
        Header: 'Requested By',
        accessor: 'requested_by',
      }],
    []
  )

  const columnsAccount = React.useMemo(
    () => [

      {
        Header: 'Date',
        accessor: 'date',
        id: 'date'
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Particular',
        accessor: 'particular',
      },
      {
        Header: 'Credit',
        accessor: (cell) => formatCurrency(cell['credit']),
        className: "text-end"
      },
      {
        Header: 'Debit',
        accessor: (cell) => formatCurrency(cell['debit']),
        className: "text-end"
      },
      {
        Header: 'Balance',
        accessor: (cell) => formatCurrency(cell['balance']),
        className: "text-end"
      }],
    []
  )

  const columnsExpense = React.useMemo(
    () => [

      {
        Header: 'Date',
        accessor: 'date',
        id: 'date'
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Mode',
        accessor: 'mode',
      }
      ,
      {
        Header: 'Description',
        accessor: 'desription',
      },

      {
        Header: 'Credit',
        accessor: (cell) => formatCurrency(cell['credit']),
        className: "text-end"
      },
      {
        Header: 'Debit',
        accessor: (cell) => formatCurrency(cell['debit']),
        className: "text-end"
      },
      {
        Header: 'Balance',
        accessor: (cell) => formatCurrency(cell['balance']),
        className: "text-end"
      },
    ],
    []
  )

  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  }, [dispatch]);



  const theme = useSelector((state) => state.ThemeOptions);

  const generateTabs = (report) => {

    return (
      <React.Fragment>
        <Row className="mb-3 g-2">
          <Col sm={12} md={3}>
            <div style={{ position: "relative" }} className="dp">
              <DateRangePicker maxDate={new Date()}

                format="dd-MM-yyyy"
                onChange={onChange}
                value={[startDate, endDate]}
                calendarIcon={<i className="fa fa-calendar"></i>}
                // clearIcon = {null}
                className="form-control"
                isClearable={true}
              ></DateRangePicker>
              <label className="black-text">Date</label>
            </div>
          </Col>
          {
            report === 'Company' ? "" :

            (report === "Expense" ?
              (<React.Fragment>
                {ownPermission ? null :
                  <Col sm={12} md={3}>
                    <Typeahead
                      id="floating-user-lbl"
                      onChange={(e) => {
                        setSelected(e[0]?.id)
                        setSelectedName(e[0]?.name)
                      }}
                      onBlur={() => {
                        if (!selected) {
                          userTypeRef.current?.clear();
                          setSelectedName(null);
                        }
                      }}
                      clearButton
                      labelKey="name"
                      options={filterUsersSearch(users)}
                      ref={userTypeRef}
                      placeholder="Select a user"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingUser" label="Select a user">
                            <Form.Control
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />

                          </FloatingLabel>

                        );
                      }}
                      selected={selected && users ? users.filter(e => e.id === Number(selected)) : []}
                    />
                  </Col>
                }

              </React.Fragment>) :
              <React.Fragment>
                <Col sm={12} md={3} >
                  <FloatingLabel controlId="floatingSide" label={`Select Client/Vendor${report.toLowerCase() === "payment" ? "/labour/user" : ""}`}>
                    <Form.Select
                      value={selectedSide}
                      onChange={e => {
                        setSelectedSide(e.target.value)
                        setSelected(null);
                        setSelectedName(null);
                        setSelectedProject(null);
                        setSelectedProjectName(null);
                      }}
                    >
                      <option value="0"> Select side</option>
                      <option value="Client">Client</option>
                      <option value="Vendor">Vendor</option>
                      {
                        report.toLowerCase() === "payment" ?
                          <React.Fragment>
                            <option value="Labour">Labour</option>
                            <option value="Expense">Expense</option>
                          </React.Fragment> : null
                      }

                    </Form.Select>
                  </FloatingLabel>
                </Col>
                {selectedSide === "0" ? null :

                  <Col sm={12} md={3} >
                    {selectedSide === "Client" ? (
                      <Typeahead
                        id="floating-label-clt-filter"
                        onChange={(e) => {
                          setSelected(e[0]?.id);
                          setSelectedName(e[0]?.name);
                          setSelectedProject(null);
                          setSelectedProjectName(null);
                        }}
                        onBlur={() => {
                          if (!selected) {
                            cltTypeRef.current?.clear();
                            setSelected(null)
                            setSelectedProject(null);
                            setSelectedProjectName(null);
                            setSelectedName(null);
                          }
                        }}
                        clearButton
                        labelKey="name"
                        options={filterClientsSearch(clients, costcenter)}
                        ref={cltTypeRef}
                        placeholder="Choose a client"
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                          return (

                            <FloatingLabel controlId="floatingClientFilter" label="Select a client">
                              <Form.Control
                                {...inputProps}
                                ref={(node) => {
                                  inputRef(node);
                                  referenceElementRef(node);
                                }}
                              />
                            </FloatingLabel>

                          );
                        }}
                        selected={selected ? clients.filter(e => e.id === selected) : []}
                      />) :
                      selectedSide === "Vendor" ?
                        (<Typeahead
                          id="floating-label-ven-filter"
                          onChange={(e) => {
                            setSelected(e[0]?.id);
                            setSelectedName(e[0]?.name);
                          }}
                          clearButton
                          onBlur={() => {
                            if (selected === null) {
                              venTypeRef.current?.clear();
                              setSelected(null);
                              setSelectedName(null);
                            }
                          }}

                          labelKey="name"
                          options={filterVendorsSearch(vendors)}
                          ref={venTypeRef}
                          placeholder="Choose a vendor"
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (

                              <FloatingLabel controlId="floatingVendorIdFilter" label="Select a vendor">
                                <Form.Control
                                  {...inputProps}
                                  ref={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                              </FloatingLabel>

                            );
                          }}
                          selected={selected !== null ? vendors.filter(e => e.id === selected) : []}
                        />)
                        : selectedSide === "Labour" ? (
                          <Typeahead
                            id="floating-label-labour-filter"
                            onChange={(e) => {
                              setSelected(e[0]?.id);
                              setSelectedName(e[0]?.name);
                            }}
                            clearButton
                            onBlur={() => {
                              if (selected === null) {
                                labTypeRef.current?.clear();
                                setSelected(null);
                                setSelectedName(null);
                              }
                            }}

                            labelKey="name"
                            options={filterLabourSearch(labours, costcenter)}
                            ref={labTypeRef}
                            placeholder="Choose a Labour"
                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                              return (

                                <FloatingLabel controlId="floatingLabourIdFilter" label="Select a labour">
                                  <Form.Control
                                    {...inputProps}
                                    ref={(node) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                  />
                                </FloatingLabel>

                              );
                            }}
                            selected={selected !== null ? labours.filter(e => e.id === selected) : []}
                          />
                        )
                          : selectedSide === "Expense" ? (<Typeahead
                            id="floating-user-lbl"
                            onChange={(e) => {
                              setSelected(e[0]?.id)
                              setSelectedName(e[0]?.name)
                            }}
                            onBlur={() => {
                              if (!selected) {
                                userTypeRef.current?.clear();
                                setSelectedName(null);
                              }
                            }}
                            clearButton
                            labelKey="name"
                            options={filterUsersSearch(users)}
                            ref={userTypeRef}
                            placeholder="Select a user"
                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                              return (

                                <FloatingLabel controlId="floatingUser" label="Select a user">
                                  <Form.Control
                                    {...inputProps}
                                    ref={(node) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                  />

                                </FloatingLabel>

                              );
                            }}
                            selected={selected && users ? users.filter(e => e.id === Number(selected)) : []}
                          />) :
                            null
                    }
                  </Col>
                }
              </React.Fragment>)
          }
          <Col sm={12} md={3} >
            <Typeahead
              disabled={report === 'Expense' && (generalExp || allExp)}
              id="floating-label-pro"
              onChange={(e) => {
                setSelectedProject(e[0]?.id)
                setSelectedProjectName(e[0]?.name);
              }}
              clearButton
              labelKey="name"
              onBlur={() => {
                if (!selectedProject) {
                  proTypeRef.current?.clear();
                  setSelectedProjectName(null);
                }
              }}
              options={projects && (selectedSide !== "Client" || (selected && selected !== "0")) ?
                filterProjectsSearch(projects, costcenter).filter(e => selectedSide !== "Client" || Number(e.clientid) === Number(selected)) : []}

              ref={proTypeRef}

              placeholder="Choose A Project"
              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                return (

                  <FloatingLabel controlId="floatingProjectFilter" label="Select a project">
                    <Form.Control
                      {...inputProps}
                      ref={(node) => {
                        inputRef(node);
                        referenceElementRef(node);
                      }}
                    />
                  </FloatingLabel>

                );
              }}
              selected={selectedProject ? projects.filter(e => e.id === selectedProject) : []}
            />
          </Col>

          {
            report === 'Expense' ?
              <React.Fragment>

                <Col sm={12} md={1}>
                  <div className="form-check form-switch pt-3">
                    <input className="form-check-input" type="checkbox" id="flexSwitchGeneralType" checked={generalExp} disabled={allExp}
                      onChange={(e) => {

                        setGeneralExp(e.target.checked);
                        setSelectedProject(null);

                      }
                      } />
                    <label className="form-check-label" htmlFor="flexSwitchGeneralType"><b>General Expense</b></label>
                  </div>
                </Col>
                <Col sm={12} md={1}>
                  <div className="form-check form-switch pt-3">
                    <input className="form-check-input" type="checkbox" id="flexSwitchAllType" checked={allExp}
                      onChange={(e) => {
                        setAllExp(e.target.checked)
                        setGeneralExp(false);
                        setSelectedProject(null);

                      }
                      } />
                    <label className="form-check-label" htmlFor="flexSwitchAllType"><b>All Expense</b></label>
                  </div>
                </Col>
              </React.Fragment>
              : null
          }

          {
            report.toLowerCase() === "invoice" ?
              <Col sm={12} md={3}>
                <div className="form-check form-switch pt-3">
                  <input className="form-check-input" type="checkbox" id="flexSwitchExclude"
                    checked={execludeAuto}
                    onChange={(e) => {
                      setExecludeAuto(e.target.checked)

                    }} />
                  <label className="form-check-label" htmlFor="flexSwitchExclude"><b>Exclude Auto  </b></label>
                </div>
              </Col> : null
          }


          <Col >
            <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">

              <Button variant="primary" size="sm" onClick={search}>View</Button>
              <Button variant="success" size="sm" onClick={handleDownload}>Download</Button>
            </div>
          </Col>

        </Row>

        <hr />

        {
          report === 'Payment' ?
            <Table columns={columnsPayment} data={tableData} /> :
            report === 'Invoice' ?
              <Table columns={columnsInvoice} data={tableData} />
              : report === 'Expense' ?
                <Table columns={columnsExpense} data={tableData} />
                : <Table columns={columnsAccount} data={tableData} />
        }</React.Fragment>);
  }


  return (
    <React.Fragment>
      <Container fluid >

        <Card>

          <Card.Header className={`d-flex ${theme.headerBackgroundColor}`}>
            <h6 className="float-start flex-fill">{report} Statement  </h6>

          </Card.Header>

          <Card.Body className="p-0">
            {
              !accountPermission && !invoicePermission && !paymentPermission && !expensePermission ?
              <Alert variant="warning" className="">Statement permission not available</Alert> : null 
            }
            <Tabs activeKey={report}
              onSelect={(k) => setReport(k)} className="mb-0 pt-2">
                {
                  accountPermission ? <Tab eventKey="Account" title="Account" className="p-2">
                  {generateTabs("Account")}
                </Tab> :null
                }
                {
                  invoicePermission ? <Tab eventKey="Invoice" title="Invoice" className="p-2">
                  {generateTabs("Invoice")}
                </Tab> : null
                }
                {
                  paymentPermission ? <Tab eventKey="Payment" title="Payment" className="p-2">
                  {generateTabs("Payment")}
                </Tab> : null
                }
                {
                  expensePermission ? <Tab eventKey="Expense" title="Expense" className="p-2">
                  {generateTabs("Expense")}
                </Tab> : null
                }
                {
                  expensePermission ? <Tab eventKey="Company" title="Company" className="p-2">
                  {generateTabs("Company")}
                </Tab> : null
                }
            </Tabs>

          </Card.Body>

        </Card>





      </Container>
      {/* <Confirm show={showConfirm} confirmation="Are you sure?" confirmCallBack={deletePayRequestOrder}></Confirm> */}
    </React.Fragment >);
}

// }
